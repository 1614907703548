* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  margin-top: 80px;
}

h1 {
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 20px;
  font-weight: 300;
  color: #70d862;
}

.releases-choices{
  margin: 30px 0 80px;
}
.releases-choices .release {
  margin: 0 5px;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #e7e7e7;
  padding: 10px; 
  width: 100px;
  display: inline-block;
  border-radius: 4px;
  color: #70d862;
  font-weight: 700;
}
.releases-choices .release.active {
 color: #fff; 
 background: #70d862;
 border: none;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background: #FBCE4B;
  padding: 40px 0;
  border-radius: 12px;
}

.card {
  width: 20%;
  padding: 10px;
  position: relative;
}

.card a {
  text-decoration: none;
  color: #000;
}

.card-image {
  width: 100%;
  height: auto;
  border: 5px solid #fff;
  display: block;
  cursor: pointer;
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  text-transform: uppercase;
  font-size: 1.3rem;
  height: 70px;
  font-weight: 400;
  opacity: 0.4;
  overflow: hidden;
}

.card-overlay {
  display: flex;
  opacity: 0;
  transition: opacity 0.15s;
  pointer-events: none;
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 110px);
  background: #fff;
  z-index: 10;
  top: 10px;
  left: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.card-image:hover ~ .card-overlay {
  opacity: 1;
  transition: opacity 0.15s;
}

.card-artists {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.card-release-date {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 300;
  margin: 10px;
}

.card-type {
  background: #FBCE4B;
  font-size: 0.8rem;
  padding: 5px 10px;
  text-transform: uppercase;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 3.5rem;
    padding: 0 20px;
  }
  .card {
    width: 33.3%;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
    padding: 0 20px;
  }
  .card {
    width: 50%;
  }
}